import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'

const IndexPage = () => {
    return(
        
        <Layout>
            <h1>Hello. It's @jsonify.</h1>
            <h2>I'm a developer in the Seattle area.</h2>
            <h3>Need a developer? <Link to="/contact">Contact Me</Link></h3>
        </Layout>
    )
}

export default IndexPage
